<template>
  <nav class="main-top-nav">
    <router-link
      v-if="!passwordPage"
      :style="navStyle"
      :to="arrowAction"
      replace
      style="color: #b4b4b4; font-size: 16px"
    >
      <img src="../assets/images/back_arrow.svg" alt="" />
    </router-link>
    

    <div v-else style="color: #b4b4b4; font-size: 16px" @click="clickAction">
        <img src="../assets/images/back_arrow.svg" alt="" />
      </div>
      <!-- v-if="hasLogo" -->
    <img
      style="height: 40px; display: block; margin: 0 auto"
      src="../../guardian/assets/images/logo-color.png"
      alt="logo"
    />

    <router-link
      v-if="isHelpVisible"
      :to="helpAction"
      replace
      style="font-size: 0.9rem; color: var(--main); font-family: var(--fredoka)"
    >
      Help
    </router-link>
  </nav>
</template>

<script>
  export default {
    name: "TopNavigation",
    props: {
      navStyle: {
        type: Object,
        default: () => ({}),
      },
      arrowAction: {
        type: String,
        default: "",
      },
      helpAction: {
        type: String,
        default: "",
      },
      isHelpVisible: {
        type: Boolean,
        default: true,
      },
      hasLogo: {
        type: Boolean,
        default: false,
      },
      passwordPage: {
        type: Boolean,
        default: false,
      },
      clickAction: {
        type: Function,
        default: () => {},
      },
    },
  };
</script>
